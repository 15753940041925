import React from "react"
import styled from "styled-components"
import { useSwiper } from "../hooks/useSwiper"
import SliderControls from "./sliderControls"
import { SmallMobileFont } from "../typography"
import BaseImg from "../baseImg"

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Slider = styled.div`
  width: 100%;
  display: flex;
`

const SlideContent = styled.div`
  display: flex;
  padding: 0 10px;
  @media (max-width: 1195px) {
    flex-direction: column;
    align-items: center;
  }
`
const ImageWrapper = styled.div`
  width: 60%;
  flex-shrink: 0;
  position: relative;
  z-index: -1;
  margin-right: -150px;
  @media (max-width: 1195px) {
    margin-right: 0;
    margin-bottom: -50px;
    width: 100%;
  }
`
const ContentWrapper = styled.div`
  background: #fff;
  padding: 2.25rem 2rem;
  box-shadow: 0px 3px 6px #00000029;
  font: 400 1rem/1.625 "Hind";
`

const OverlapSlider = ({ slides }) => {
  const { swiperElement, swiperInstance, activeSlideIndex } = useSwiper(slides)

  return (
    <div
      style={{
        margin: "100px 0 140px 0",
      }}
    >
      <Slider>
        <div className="swiper-container" ref={swiperElement}>
          <ul style={{ margin: "0" }} className="swiper-wrapper">
            {slides.map((slide) => {
              const { title, text, image } = slide
              return (
                <Slide className="swiper-slide" key={title}>
                  <SlideContent>
                    <ImageWrapper>
                      {image ? <BaseImg localFile={image.localFile} /> : null}
                    </ImageWrapper>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <ContentWrapper>
                        <h3 style={{ marginBottom: "0.75rem" }}>
                          <SmallMobileFont>{title}</SmallMobileFont>
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                      </ContentWrapper>
                    </div>
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
      <SliderControls
        position="bottom-right"
        swiperInstance={swiperInstance}
        activeSlideIndex={activeSlideIndex}
        slides={slides}
      />
    </div>
  )
}
export default OverlapSlider
