import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import Swiper from "swiper"
import BaseImg from "../baseImg"

const ArrowButton = styled.button`
  background: none;
  border: none;
  height: 40px;
  cursor: pointer;
  margin-${(props) => (props.side === "left" ? "right" : "left")}: 30px;
  position: relative;
  :focus{
    outline: none;
    ::before{
      border-color: ${(props) => props.theme.primary};
    }
  }
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-bottom: 2px ${(props) => props.theme.secondary} solid;
    border-left: 2px ${(props) => props.theme.secondary} solid;
    transform: translate(${(props) =>
      props.side === "left" ? "-20%" : "-80%"}, -50%)
      rotate(${(props) => (props.side === "left" ? "45deg" : "-135deg")});
      position: absolute;
    }
    `

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Slider = styled.div`
  width: calc(100% - 226px);
  margin: 0 113px 140px;
  display: flex;
  @media (max-width: 768px) {
    width: calc(100% - 44px);
    margin: 0 22px 140px;
  }
`

const SlideContent = styled.div`
  display: flex;
  width: 100%;
`

const Flip = styled.div`
  position: relative;
  margin: auto;
  z-index: 2;
  padding: 15px !important;
  height: 230px;
  width: 230px;
  .flip__content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
  }
  .gatsby-image-wrapper {
    transition: visibility 0s 0.25s;
  }
  :hover {
    .gatsby-image-wrapper {
      visibility: hidden;
    }
    .flip__content {
      transform: rotateY(180deg);
    }
  }
`
const FlipFront = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`
const FlipBack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  text-align: center;
  background-color: #fff;
  backface-visibility: hidden;
  box-shadow: 0px 3px 6px #00000029;
  height: 100%;
  padding: 5px;
  width: 100%;
  transform: rotateY(180deg);
`

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  border: 1px solid ${(props) => props.theme.secondary};
  width: 100%;
  padding: 0 56px;
  height: 76px;
  transform: translateY(-50%);
  @media (max-width: 768px) {
    padding: 0 12px;
  }
`

const TeamSlider = ({ team }) => {
  const swiperElement = useRef(null)
  const swiperInstance = useRef(null)
  const slidesPerView = useRef(4)
  useEffect(() => {
    const innerWidth = window.innerWidth
    if (innerWidth < 768) {
      slidesPerView.current = 1
    } else if (innerWidth < 1095) {
      slidesPerView.current = 2
    } else if (innerWidth < 1300) {
      slidesPerView.current = 3
    }
  }, [])
  useEffect(() => {
    swiperInstance.current = new Swiper(swiperElement.current, {
      loop: true,
      spaceBetween: 26,
      slidesPerView: slidesPerView.current,
      on: {
        slideNextTransitionStart: function () {
          const isTheLastSlide = this.activeIndex > team.length
          if (isTheLastSlide) {
            return this.slideToLoop(0)
          }
        },
        slidePrevTransitionStart: function () {
          const isTheFirstSlide = this.activeIndex - slidesPerView.current < 0
          if (isTheFirstSlide) {
            return this.slideToLoop(team.length - slidesPerView.current)
          }
        },
      },
    })
    return () => swiperInstance.current.destroy()
  }, [team.length])
  return (
    <div style={{ position: "relative" }}>
      <Slider>
        <div className="swiper-container" ref={swiperElement}>
          <ul style={{ margin: "0" }} className="swiper-wrapper">
            {team.map((member) => {
              const { name, role, image } = member
              return (
                <Slide className="swiper-slide" key={name}>
                  <SlideContent>
                    <Flip>
                      <div className="flip__content">
                        <FlipBack>
                          <span
                            style={{
                              fontSize: "24px",
                              fontWeight: "700",
                              lineHeight: "29px",
                            }}
                          >
                            {name}
                          </span>
                          <p
                            style={{
                              fontSize: "20px",
                              fontFamily: "Montserrat",
                              marginTop: "0.25rem",
                            }}
                          >
                            {role}
                          </p>
                        </FlipBack>
                        <FlipFront>
                          {image ? (
                            <BaseImg localFile={image.localFile} />
                          ) : null}
                        </FlipFront>
                      </div>
                    </Flip>
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
      <ArrowsWrapper>
        <ArrowButton
          type="button"
          aria-label="Show previous slide"
          side="left"
          onClick={() => swiperInstance.current.slidePrev()}
        />
        <ArrowButton
          type="button"
          aria-label="Show next slide"
          onClick={() => swiperInstance.current.slideNext()}
        />
      </ArrowsWrapper>
    </div>
  )
}
export default TeamSlider
