import React from "react"
import Img from "gatsby-image"
import { SmallMobileFont } from "./typography"
import styled from "styled-components"

const ImageColumn = styled(Img)`
  width: 60%;
  margin-left: auto;
  display: block;
  margin-bottom: -150px;
  position: relative;
  z-index: -1;
  @media (max-width: 900px) {
    width: 90%;
    margin-bottom: -10%;
  }
`

const ContentColumn = styled.div`
  background: #fff;
  width: 60%;
  padding: 34px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 140px;
  @media (max-width: 900px) {
    width: 90%;
  }
`

const ContentImageColumns = ({ title, content, image }) => {
  return (
    <>
      <ImageColumn fluid={image} />
      <ContentColumn>
        <h2 style={{ marginBottom: "0.75rem" }}>
          <SmallMobileFont>{title}</SmallMobileFont>
        </h2>
        <p
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ marginBottom: "0", lineHeight: "1.625" }}
        ></p>
      </ContentColumn>
    </>
  )
}
export default ContentImageColumns
