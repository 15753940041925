import React from "react"
import styled, { css } from "styled-components"
import { BigMobileFont } from "./typography"
import BaseImg from "./baseImg"
import { useAnimatedBorder } from "../helpers"

const Box = styled.div`
  font-family: "Hind";
  line-height: 1.625em;
  padding: ${(props) =>
    props.hasImages ? "3rem 232px 3rem 3rem" : "2.5rem 2rem 2.5rem 6.5rem"};
  border: 1px solid ${(props) => props.theme.secondary};
  border-${(props) => (props.hasImages ? "right" : "left")}: none;
  position: relative;
  margin-right: 4.5rem;
  max-width: 43rem;
  @media screen and (max-width: 1195px) {
    margin-right: 0;
  }
  ${(props) =>
    !props.hasImages &&
    css`
      ::before,
      ::after {
        border-left: 1px solid ${(props) => props.theme.secondary};
      }
      @media screen and (max-width: 768px) {
        padding: 1.5rem 1.75rem;
        margin-top: 1.5rem;
      }
    `}

  ::before,
  ::after {
    content: "";
    display: block;
    position: absolute;
    ${(props) => (props.hasImages ? "right" : "left")}: 0;
    top: 0;
    height: 25%;
    width: 100%;
    transform: scaleY(calc(1 + var(--scale)));
    transform-origin: top;
    transition: transform 1s ease-out;
  }
  ::after {
    top: unset;
    bottom: 0;
    transform: scaleY(calc(1 - var(--scale)));
    transform-origin: bottom;
  }
  ${(props) =>
    props.hasImages &&
    css`
      ::before,
      ::after {
        border-right: 1px solid ${(props) => props.theme.secondary};
      }
      @media screen and (max-width: 900px) {
        padding: 2rem;
        border-left: none;
        max-width: none;
        ::before,
        ::after {
          border-left: 1px solid ${(props) => props.theme.secondary};
        }
      }
    `}
`
const Header = styled.h2`
  margin-bottom: 0;
  transform: translateX(4.5rem);
  max-width: 450px;
  @media screen and (max-width: 1195px) {
    margin-left: -4.5rem;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
    transform: none;
  }
`

const Subheader = styled.h3`
  font-family: "Hind";
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
`

const SideTitleWrapper = styled.div`
  display: flex;
  align-items: ${(props) => (props.hasImages ? "" : "center")};
  justify-content: center;
  margin: 30px 0;
  ${(props) =>
    !props.hasImages &&
    css`
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 60px 0;
      }
    `}
  ${(props) =>
    props.hasImages &&
    css`
      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        margin: 60px 0;
      }
    `}
`

const ImagesWrapper = styled.div`
  position: relative;
  transform: translateY(calc(50% - 140px));
  margin-left: -200px;
  width: 500px;
  .order-0 {
    position: absolute;
    top: 0;
    left: 0;
    width: 76%;
  }
  .order-1 {
    position: absolute;
    top: 120px;
    left: 14%;
    width: 47%;
  }
  .order-2 {
    position: absolute;
    top: 50px;
    left: 38%;
    width: 62%;
  }
  @media (max-width: 900px) {
    width: 100%;
    transform: none;
    margin-left: 0;
    height: 50vw;
  }
  @media (max-width: 768px) {
    height: 60vw;
  }
`

const SideTitleBox = ({ title, subtitle, text, images }) => {
  const [boxRef] = useAnimatedBorder()

  return (
    <SideTitleWrapper hasImages={images !== undefined}>
      {title !== undefined ? (
        <Header>
          <BigMobileFont>{title}</BigMobileFont>
        </Header>
      ) : null}
      <Box hasImages={images !== undefined} ref={boxRef}>
        <div>
          <Subheader>{subtitle}</Subheader>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </Box>
      {images !== undefined ? (
        <ImagesWrapper>
          {images.map((localFile, index) => (
            <div key={index} className={`order-${index}`}>
              <BaseImg localFile={localFile} />
            </div>
          ))}
        </ImagesWrapper>
      ) : null}
    </SideTitleWrapper>
  )
}

export default SideTitleBox
